import React from "react";
import companies from "../data/companies";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

const Companies = () => {
  return (
    <section className="companies">
      <div className="container">
        <div className="row">

          <OwlCarousel className='owl-theme' loop margin={50} dots items={4} autoplay autoplayHoverPause responsive={{
            0: {
              items: 2
            },
            780: {
              items: 2
            },
            991: {
              items: 3
            },
            1200: {
              items: 4
            }
          }}>
            {companies.map((company, index) => (
              <Link to={company?.link} key={index}
              // target={company?.link ? "_blank" : ""}
              >
                <figure className="mb-0">
                  <img className="company_image" src={company.src} alt="" />
                </figure>
              </Link>
            ))}
          </OwlCarousel>

        </div>
      </div>
    </section>
  );
};

export default Companies;
