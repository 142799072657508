import React, { useState } from "react";
import data from "../data/faqs";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const { i18n, t } = useTranslation();

  const [isClicked, setIsClicked] = useState(new Array(data.length).fill(false));

  const handleClick = (index) => {
    const newClickedState = [...isClicked];


    newClickedState[index] = !newClickedState[index];


    setIsClicked(newClickedState);
  };

  return (
    <div id="faqs" className="faqs default-padding">
      <div className="container">
        <article className="mb-5">
          <h3 className="text-capitalize text-center">{t(`faq.title`)}</h3>
          <p className="text-break text-center">{t(`faq.sub`)}</p>
        </article>
        <div className="row">
          {data.map((faq, index) => (
            <div className="col-md-6 mb-3" key={index} data-aos="zoom-in-down">
              <div className="card rounded" onClick={() => handleClick(index)}>
                <div className="card-body">
                  <p className="mb-0">
                    <svg
                      width="20"
                      height="20"
                      className="me-3"
                      viewBox="0 0 55 55"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_145_7)">
                        <path
                          d="M47.928 31.8759L15.7101 53.7885C11.1985 56.8648 5 53.727 5 48.3729L5 7.62889C5 2.27479 11.1985 -0.863002 15.7101 2.20863L47.928 24.1307C48.5679 24.5664 49.0903 25.1457 49.451 25.8194C49.8117 26.4932 50 27.2414 50 28.0009C50 28.7604 49.8117 29.5087 49.451 30.1824C49.0903 30.8561 48.5679 31.4401 47.928 31.8759Z"
                          fill="#212c4f"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_145_7">
                          <rect width="55" height="55" fill="#212c4f" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span>{i18n.language === "en" ? faq.questionEN : faq.questionFR}</span>
                  </p>
                </div>
                <div className={`details ${isClicked[index] ? "clicked" : ""}`}>
                  {i18n.language === "en" ? faq.answerEN : faq.answerFR}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
