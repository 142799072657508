import React from "react";
import bg from "../assets/images/studio/bg.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import games from "../data/games";

const Studio = () => {
  const { t } = useTranslation();

  return (
    <div className="studio default-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <article>
              <div className="mb-4">
                <h2 data-aos="zoom-in-up">
                  {t(`studio.title.normal`)} <span>{t(`studio.title.span`)}</span>
                </h2>
                <p data-aos="zoom-in-up">
                  {t(`studio.sub`)}
                </p>
              </div>
              <div className="games_images d-flex align-items-center flex-wrap gap-2">

                <OwlCarousel className='owl-theme' loop margin={10} autoplay nav={false} dots={false} autoplayHoverPause responsive={{
                  0: {
                    items: 1
                  },
                  780: {
                    items: 2
                  },
                  991: {
                    items: 3
                  }
                }}>
                  {
                    games.map((game, index) => (
                      <Link
                        to={game.link}
                        key={index}
                        rel="noopener noreferrer"
                        data-aos="fade-right"
                        data-aos-offset={200 + index + 50}
                        data-aos-easing="ease-in-sine"
                        className="text-decoration-none"
                      >
                        <div className="overflow-hidden">
                          <figure className="mb-0">
                            <img src={game.src} alt="" />
                          </figure>
                          <p className="text-center text-dark mb-0 pt-1">{game.title}</p>

                        </div>
                      </Link>
                    ))
                  }
                </OwlCarousel>

              </div>
              <Link
                to={`#`}

                className="text-decoration-none"
              >
                <button className="btn mt-3" type="button">
                  <span>go to site</span>
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 9.77516e-06C15.515 9.77516e-06 20 4.26171 20 9.5C20 14.7383 15.515 19 10 19C4.486 19 0 14.7383 0 9.5C0 4.26171 4.486 9.77516e-06 10 9.77516e-06"
                      fill="white"
                    />
                    <path
                      d="M8.55748 5.49035C8.74848 5.49035 8.94048 5.5597 9.08648 5.6984L12.5735 8.99489C12.7145 9.12884 12.7935 9.31029 12.7935 9.50029C12.7935 9.68934 12.7145 9.87079 12.5735 10.0047L9.08648 13.3031C8.79348 13.5805 8.31948 13.5805 8.02648 13.3012C7.73448 13.0219 7.73548 12.5707 8.02848 12.2933L10.9815 9.50029L8.02848 6.7073C7.73548 6.4299 7.73448 5.9796 8.02648 5.7003C8.17248 5.5597 8.36548 5.49035 8.55748 5.49035"
                      fill="#01AED8"
                    />
                  </svg>
                </button>
              </Link>
            </article>
          </div>
          <div className="col-lg-6 col-md-12 position-relative">
            <Link to={`#`} >
              <figure className="mb-0" data-aos="zoom-out-left">
                <img src={bg} alt="" />
              </figure>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Studio;
