import React from 'react'
import { Link } from 'react-router-dom'

const Banner = ({ page }) => {
    return (
        <div className='banner pt-5 pb-5 mb-5'>
            <div className="row">
                <div className="col-md-12 text-center">
                    <Link to="#" className='text-decoration-none fw-bold text-capitalize fs-2'>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" color="#1260ac" className="float-left mr-2 items-center" height="50" width="50" xmlns="http://www.w3.org/2000/svg"><path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"></path></svg>
                        <span className='ps-2'>{page}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Banner