import game1 from "../assets/images/studio/1.png";
import game2 from "../assets/images/studio/2.png";
import game3 from "../assets/images/studio/3.png";
// import game4 from "../assets/images/studio/4.png";
import game5 from "../assets/images/studio/5.webp";

const games = [
    {
        title: "Bricks Ball Builder",
        link: "#",
        src: game5
    },
    {
        title: "Brick Climb Battle",
        link: "#",
        src: game1
    },
    {
        title: "Scooter Factory",
        link: "#",
        src: game2
    },
    {
        title: "Simply Juice!",
        link: "#",
        src: game3
    },
]

export default games;