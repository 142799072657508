import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <div>
          {/* LOCATION */}
          <Link to={`#`} className="text-decoration-none">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon fontSize={14} color="#fff" icon={faLocationDot} />
              <span className="ps-2 text-white ">N 5, res Firdaous, Bd Moulay Rachid, Marrakech 40000</span>
            </div>
          </Link>
        </div>
        <div className="d-flex align-items-center gap-3">
          {/* START PHONE */}
          <Link to={`#`} className="text-decoration-none">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon fontSize={14} color="#fff" icon={faPhone} />
              <span className="ps-2 text-white ">08086-01751</span>
            </div>
          </Link>


          {/* START MAIL */}
          <Link to={`mailto:example@yourdomain.net`} className="text-decoration-none">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon fontSize={14} color="#fff" icon={faEnvelope} />
              <span className="ps-2 text-white">example@yourdomain.net</span>
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Header;
