import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import Statistics from "../components/Statistics";
import Clients from "../components/Clients";
import Studio from "../components/Studio";
import Companies from "../components/Companies";
import FAQs from "../components/FAQs";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Studio />
      <Statistics />
      <Clients />
      <Companies />
      <FAQs />
    </>
  );
};

export default Home;
