import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.webp";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const pages = ["home", "about", "services", "faqs", "applications"];

  const navRef = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isClicked, setIsClicked] = useState(true);


  const handleClickNavItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setIsClicked(!isClicked);
  }

  useEffect(() => {
    const NavbarScrollActive = () => {
      var scrollTop = window.scrollY;

      if (scrollTop > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", NavbarScrollActive);

    // hide the menu
    setIsClicked(!isClicked);

    return () => {
      window.removeEventListener("scroll", NavbarScrollActive);
    };
  }, []);

  const clickOnBurgerIcon = () => {
    setIsClicked(!isClicked);
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const NavigateToContactPage = () => {
    navigate('/contact');
    scrollToTop();
  }

  return (
    <nav className={`navbar ${isScrolled ? "active" : ""}`} ref={navRef}>
      <div className="container">
        <Link to={`/`}>
          <div className="brand-logo">
            <img src={logo} alt="" />
          </div>
        </Link>

        <ul className="m-0 p-0 hide_in_mobile">
          {pages.map((page, index) => (
            <li
              className={activeIndex === index ? "active" : ""}
              key={index}
              onClick={() => {
                handleClickNavItem(index);
                scrollToSection(page.toLowerCase());
                if (page.toLowerCase() === "home" || page.toLowerCase() === "applications") {
                  scrollToTop();
                }
              }}
            >
              <Link
                to={`${page.toLowerCase() === "home" ? "/" : page.toLowerCase() === "applications" ? `/${page.toLowerCase()}` : `/#${page.toLowerCase()}`}`}
                className="text-decoration-none text-capitalize"
              >
                {t(`navbar.${page.toLowerCase()}`)}
              </Link>
            </li>
          ))}
        </ul>

        <div className="d-flex align-items-center gap-3">
          <button className="btn btn_contact hide_in_mobile" onClick={NavigateToContactPage}>Contact us</button>
          {
            i18n.language === "en" ? <button className="btn btn_lang" onClick={() => i18n.changeLanguage("fr")}>French</button> : <button className="btn btn_lang" onClick={() => i18n.changeLanguage("en")}>English</button>
          }
        </div>


        <div className="burger-icon" onClick={clickOnBurgerIcon}>
          <div className={`hamburger ${isClicked ? "is-active" : ""}`} id="hamburger-11">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>

        <div className={`mobile_menu ${isClicked ? "show" : ""}`}>
          <ul className="m-0 p-0">
            {pages.map((page, index) => (
              <li
                className={activeIndex === index ? "active" : ""}
                key={index}
                onClick={() => {
                  handleClickNavItem(index);
                  scrollToSection(page.toLowerCase());
                  if (page.toLowerCase() === "home" || page.toLowerCase() === "applications") {
                    scrollToTop();
                  }
                }}
              >
                <Link
                  to={`${page.toLowerCase() === "home" ? "/" : page.toLowerCase() === "applications" ? `/${page.toLowerCase()}` : `/#${page.toLowerCase()}`}`}
                  className="text-decoration-none text-capitalize"
                >
                  {page.toUpperCase()}
                </Link>
              </li>
            ))}
          </ul>
          <button className="btn btn_contact" onClick={NavigateToContactPage}>Contact us</button>
        </div>
      </div>
    </nav >
  );
};

export default Navbar;
