import React, { useEffect } from 'react'
import Banner from '../components/common/Banner'
import apps from '../data/apps'
import { useTranslation } from 'react-i18next'

const Applications = () => {

    const { i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <>
            <Banner page={'applications'} />

            <div className='apps default-padding'>
                <div className="container">
                    {
                        apps.map((app, index) => (
                            <div className="row align-items-center mb-5 py-5" key={index}>
                                <div className={`col-lg-6 col-md-12`}>
                                    <article>
                                        <h2 className='fw-bold fs-2 mb-3'>{i18n.language === "en" ? app.titleEN.normal : app.titleFR.normal}{" "}<span>{i18n.language === "en" ? app.titleEN.span : app.titleFR.span}</span></h2>
                                        <p className='text-break mb-3'>
                                            {i18n.language === "en" ? app.descEN : app.descFR}
                                        </p>
                                    </article>
                                </div>
                                <div className={`col-lg-6 col-md-12`}>
                                    <figure className={index === 2 ? 'w-600' : index === 1 ? 'w-390' : ''}>
                                        <img src={app.src} alt="" />
                                    </figure>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    )
}

export default Applications