import React from "react";
import reviews from "../data/reviews";
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from "react-i18next";

const Clients = () => {
  const { i18n, t } = useTranslation();


  return (
    <section className="clients default-padding">
      <div className="container">
        <article className="mb-5">
          <h3 className="text-capitalize text-center">
            {t(`clients.title.normal`)}  <span>{t(`clients.title.span`)}</span>
          </h3>
          <p className="text-break text-center">
            {t(`clients.sub`)}
          </p>
        </article>

        <OwlCarousel className='owl-theme' loop margin={50} autoplay autoplayHoverPause responsive={{
          0: {
            items: 1
          },
          780: {
            items: 2
          },
          991: {
            items: 3
          }
        }}>
          {reviews.map((review, index) => (
            <div className="item" data-aos="zoom-out-left" key={index}>
              <div className="card border-0">
                <div className="card-body text-center">
                  <div className="stars d-flex align-items-center justify-content-center gap-1 mb-3">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_23_2)">
                        <path d="M7.27999 1.91199C7.35335 1.78609 7.45845 1.68164 7.58478 1.60904C7.71112 1.53644 7.85428 1.49823 7.99999 1.49823C8.1457 1.49823 8.28886 1.53644 8.4152 1.60904C8.54154 1.68164 8.64663 1.78609 8.71999 1.91199L10.5833 5.11065L14.202 5.89465C14.3443 5.92558 14.4761 5.99329 14.5841 6.09101C14.6921 6.18873 14.7726 6.31306 14.8176 6.4516C14.8626 6.59014 14.8705 6.73806 14.8405 6.8806C14.8105 7.02314 14.7437 7.15533 14.6467 7.26399L12.18 10.0247L12.5533 13.708C12.5681 13.853 12.5445 13.9994 12.4849 14.1325C12.4254 14.2655 12.332 14.3807 12.2141 14.4664C12.0961 14.552 11.9578 14.6053 11.8128 14.6208C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0613L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6208C4.0422 14.6053 3.90385 14.552 3.7859 14.4664C3.66796 14.3807 3.57457 14.2655 3.51504 14.1325C3.45551 13.9994 3.43193 13.853 3.44666 13.708L3.81999 10.0247L1.35332 7.26465C1.25614 7.156 1.18916 7.02376 1.15907 6.88113C1.12897 6.7385 1.13682 6.59047 1.18182 6.45182C1.22682 6.31317 1.30741 6.18875 1.41553 6.09099C1.52365 5.99322 1.65553 5.92552 1.79799 5.89465L5.41666 5.11065L7.27999 1.91199Z" fill="#FFD233" />
                      </g>
                      <defs>
                        <clipPath id="clip0_23_2">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_23_2)">
                        <path d="M7.27999 1.91199C7.35335 1.78609 7.45845 1.68164 7.58478 1.60904C7.71112 1.53644 7.85428 1.49823 7.99999 1.49823C8.1457 1.49823 8.28886 1.53644 8.4152 1.60904C8.54154 1.68164 8.64663 1.78609 8.71999 1.91199L10.5833 5.11065L14.202 5.89465C14.3443 5.92558 14.4761 5.99329 14.5841 6.09101C14.6921 6.18873 14.7726 6.31306 14.8176 6.4516C14.8626 6.59014 14.8705 6.73806 14.8405 6.8806C14.8105 7.02314 14.7437 7.15533 14.6467 7.26399L12.18 10.0247L12.5533 13.708C12.5681 13.853 12.5445 13.9994 12.4849 14.1325C12.4254 14.2655 12.332 14.3807 12.2141 14.4664C12.0961 14.552 11.9578 14.6053 11.8128 14.6208C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0613L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6208C4.0422 14.6053 3.90385 14.552 3.7859 14.4664C3.66796 14.3807 3.57457 14.2655 3.51504 14.1325C3.45551 13.9994 3.43193 13.853 3.44666 13.708L3.81999 10.0247L1.35332 7.26465C1.25614 7.156 1.18916 7.02376 1.15907 6.88113C1.12897 6.7385 1.13682 6.59047 1.18182 6.45182C1.22682 6.31317 1.30741 6.18875 1.41553 6.09099C1.52365 5.99322 1.65553 5.92552 1.79799 5.89465L5.41666 5.11065L7.27999 1.91199Z" fill="#FFD233" />
                      </g>
                      <defs>
                        <clipPath id="clip0_23_2">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_23_2)">
                        <path d="M7.27999 1.91199C7.35335 1.78609 7.45845 1.68164 7.58478 1.60904C7.71112 1.53644 7.85428 1.49823 7.99999 1.49823C8.1457 1.49823 8.28886 1.53644 8.4152 1.60904C8.54154 1.68164 8.64663 1.78609 8.71999 1.91199L10.5833 5.11065L14.202 5.89465C14.3443 5.92558 14.4761 5.99329 14.5841 6.09101C14.6921 6.18873 14.7726 6.31306 14.8176 6.4516C14.8626 6.59014 14.8705 6.73806 14.8405 6.8806C14.8105 7.02314 14.7437 7.15533 14.6467 7.26399L12.18 10.0247L12.5533 13.708C12.5681 13.853 12.5445 13.9994 12.4849 14.1325C12.4254 14.2655 12.332 14.3807 12.2141 14.4664C12.0961 14.552 11.9578 14.6053 11.8128 14.6208C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0613L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6208C4.0422 14.6053 3.90385 14.552 3.7859 14.4664C3.66796 14.3807 3.57457 14.2655 3.51504 14.1325C3.45551 13.9994 3.43193 13.853 3.44666 13.708L3.81999 10.0247L1.35332 7.26465C1.25614 7.156 1.18916 7.02376 1.15907 6.88113C1.12897 6.7385 1.13682 6.59047 1.18182 6.45182C1.22682 6.31317 1.30741 6.18875 1.41553 6.09099C1.52365 5.99322 1.65553 5.92552 1.79799 5.89465L5.41666 5.11065L7.27999 1.91199Z" fill="#FFD233" />
                      </g>
                      <defs>
                        <clipPath id="clip0_23_2">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_23_2)">
                        <path d="M7.27999 1.91199C7.35335 1.78609 7.45845 1.68164 7.58478 1.60904C7.71112 1.53644 7.85428 1.49823 7.99999 1.49823C8.1457 1.49823 8.28886 1.53644 8.4152 1.60904C8.54154 1.68164 8.64663 1.78609 8.71999 1.91199L10.5833 5.11065L14.202 5.89465C14.3443 5.92558 14.4761 5.99329 14.5841 6.09101C14.6921 6.18873 14.7726 6.31306 14.8176 6.4516C14.8626 6.59014 14.8705 6.73806 14.8405 6.8806C14.8105 7.02314 14.7437 7.15533 14.6467 7.26399L12.18 10.0247L12.5533 13.708C12.5681 13.853 12.5445 13.9994 12.4849 14.1325C12.4254 14.2655 12.332 14.3807 12.2141 14.4664C12.0961 14.552 11.9578 14.6053 11.8128 14.6208C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0613L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6208C4.0422 14.6053 3.90385 14.552 3.7859 14.4664C3.66796 14.3807 3.57457 14.2655 3.51504 14.1325C3.45551 13.9994 3.43193 13.853 3.44666 13.708L3.81999 10.0247L1.35332 7.26465C1.25614 7.156 1.18916 7.02376 1.15907 6.88113C1.12897 6.7385 1.13682 6.59047 1.18182 6.45182C1.22682 6.31317 1.30741 6.18875 1.41553 6.09099C1.52365 5.99322 1.65553 5.92552 1.79799 5.89465L5.41666 5.11065L7.27999 1.91199Z" fill="#FFD233" />
                      </g>
                      <defs>
                        <clipPath id="clip0_23_2">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_23_2)">
                        <path d="M7.27999 1.91199C7.35335 1.78609 7.45845 1.68164 7.58478 1.60904C7.71112 1.53644 7.85428 1.49823 7.99999 1.49823C8.1457 1.49823 8.28886 1.53644 8.4152 1.60904C8.54154 1.68164 8.64663 1.78609 8.71999 1.91199L10.5833 5.11065L14.202 5.89465C14.3443 5.92558 14.4761 5.99329 14.5841 6.09101C14.6921 6.18873 14.7726 6.31306 14.8176 6.4516C14.8626 6.59014 14.8705 6.73806 14.8405 6.8806C14.8105 7.02314 14.7437 7.15533 14.6467 7.26399L12.18 10.0247L12.5533 13.708C12.5681 13.853 12.5445 13.9994 12.4849 14.1325C12.4254 14.2655 12.332 14.3807 12.2141 14.4664C12.0961 14.552 11.9578 14.6053 11.8128 14.6208C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0613L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6208C4.0422 14.6053 3.90385 14.552 3.7859 14.4664C3.66796 14.3807 3.57457 14.2655 3.51504 14.1325C3.45551 13.9994 3.43193 13.853 3.44666 13.708L3.81999 10.0247L1.35332 7.26465C1.25614 7.156 1.18916 7.02376 1.15907 6.88113C1.12897 6.7385 1.13682 6.59047 1.18182 6.45182C1.22682 6.31317 1.30741 6.18875 1.41553 6.09099C1.52365 5.99322 1.65553 5.92552 1.79799 5.89465L5.41666 5.11065L7.27999 1.91199Z" fill="#FFD233" />
                      </g>
                      <defs>
                        <clipPath id="clip0_23_2">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                  </div>
                  <p className="card-text">{i18n.language === "en" ? review.descriptionEN : review.descriptionFR}</p>
                </div>
              </div>
              <div className="client_infos pt-4 text-center">
                <figure className="clientImage">
                  <img src={review.src} alt="" />
                </figure>
                <p className="pt-2  mb-0 client_name">
                  {review.name}
                </p>
                <p>{i18n.language === "en" ? review.roleEN : review.roleFR}</p>
              </div>
            </div>
          ))}
        </OwlCarousel>

      </div>
    </section>
  );
};

export default Clients;
