const faqs = [
    {
        questionEN: "Can I request to work with a specific developer from your team throughout the project?",
        questionFR: "Pourrai-je demander de travailler avec un développeur spécifique de votre équipe tout au long du projet ?",

        answerFR: "Oui, vous pouvez demander de travailler avec un seul membre tout au long de votre projet. Dans ce cas, nous garantissons une communication fluide et fructive.",
        answerEN: "Yes, you can request to work with a single team member throughout your project. In this case, we guarantee smooth and fruitful communication."
    },
    {
        questionEN: "Will your company be able to offer services tailored to my needs?",
        questionFR: "Votre entreprise pourra-t-elle proposer des services adaptés à mon besoin ?",

        answerFR: "Bien evidemment, nous tâchons à bien comprendre vos besoins afin de proposer les services y adaptés.",
        answerEN: "Certainly, we strive to understand your needs well in order to propose suitable services."
    },
    {
        questionEN: "What is the development timeline for my website/application?",
        questionFR: "Quel est le délai de développement de mon site web/application ?",

        answerFR: "Tout dépend des fonctionnalités demandées, des technologies utilisées et du type d'application/site web.",
        answerEN: "It depends on the requested features, technologies used, and the type of application/website."
    },
    {
        questionEN: "Will there be any commitment from your side after the creation of the website/application?",
        questionFR: "Y’aura-t-il un engagement de votre part après la création du site web/application ?",

        answerFR: "Effectivement, nous proposons un service après vente étalé sur une période de 2 mois. Par la suite, nous pouvons nous fier à un contrat de maintenance.",
        answerEN: "Indeed, we offer after-sales service spread over a period of 2 months. Afterwards, we can rely on a maintenance contract."
    },
    {
        questionEN: "Do you offer payment facilities?",
        questionFR: "Est-ce que vous faites des facilités de paiement ?",

        answerFR: "Oui, nous pouvons accepter un paiement sur 2/3 tranches.",
        answerEN: "Yes, we can accept payment in 2/3 installments."
    },
    {
        questionEN: "Can you work remotely?",
        questionFR: "Est-ce que vous pouvez travailler à distance ?",

        answerFR: "Nous n’avons aucun problème à travailler à distance avec nos clients. Où que vous soyez, nous ferons de votre satisfaction notre priorité.",
        answerEN: "We have no problem working remotely with our clients. Wherever you are, we will make your satisfaction our priority."
    },
];

export default faqs;
