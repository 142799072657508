import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import herobg from "../assets/images/hero/hero_bg.png";
import sequare from "../assets/images/shapes/square.svg";
import rhombus from "../assets/images/shapes/rhombus.svg";
import arrow from "../assets/images/hero/arrow.svg";
import Splitting from 'splitting';
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const { t } = useTranslation();
  const titleRef = useRef(null);
  const subRef = useRef(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setupLineSplitting();
  //   setupMaskReveal();
  // }, [])

  // function setupLineSplitting() {
  //   const results = Splitting({ target: titleRef.current, by: 'lines' });
  //   const lines = results.map((result) => result.lines);
  //   gsap.fromTo(
  //     lines,
  //     { opacity: 0, y: 24 },
  //     {
  //       opacity: 1,
  //       y: 0,
  //       stagger: 0.1,
  //       duration: .3,
  //       delay: .3,
  //     }
  //   );
  // }

  // function setupMaskReveal() {
  //   const lines = gsap.utils.toArray(subRef.current.querySelectorAll("span"));
  //   const masks = lines.map((line) => line.querySelector("div"));
  //   gsap.fromTo(
  //     masks,
  //     { scaleX: 1 },
  //     {
  //       scaleX: 0,
  //       stagger: 0.3,
  //       duration: 1,
  //       scrollTrigger: {
  //         trigger: subRef.current,
  //         scrub: true,
  //         start: "bottom bottom",
  //         end: "center center",
  //       },
  //     }
  //   );
  // }

  return (
    <section className="hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 order2">
            <h1 className="hero_title mb-3 text-anime-style-3" ref={titleRef}>
              {t(`hero.title`)}
            </h1>
            <p className="text-break hero_sub mb-3 text-anime-style-2" ref={subRef}>
              {t(`hero.sub`)}
            </p>
            <div className="d-flex align-items-center gap-5">
              <button className="btn btn_started d-flex align-items-center gap-2 position-relative" onClick={() => [navigate('/contact'), window.scroll({ top: 0, behavior: "smooth" })]}>
                <span className="text-white">{t(`hero.btn`)}</span>
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 9.77516e-06C15.515 9.77516e-06 20 4.26171 20 9.5C20 14.7383 15.515 19 10 19C4.486 19 0 14.7383 0 9.5C0 4.26171 4.486 9.77516e-06 10 9.77516e-06"
                    fill="white"
                  />
                  <path
                    d="M8.55748 5.49035C8.74848 5.49035 8.94048 5.5597 9.08648 5.6984L12.5735 8.99489C12.7145 9.12884 12.7935 9.31029 12.7935 9.50029C12.7935 9.68934 12.7145 9.87079 12.5735 10.0047L9.08648 13.3031C8.79348 13.5805 8.31948 13.5805 8.02648 13.3012C7.73448 13.0219 7.73548 12.5707 8.02848 12.2933L10.9815 9.50029L8.02848 6.7073C7.73548 6.4299 7.73448 5.9796 8.02648 5.7003C8.17248 5.5597 8.36548 5.49035 8.55748 5.49035"
                    fill="#01AED8"
                  />
                </svg>
                <img src={arrow} className="arrow" alt="" />
              </button>
              {/* <button className="btn_video_play d-flex align-items-center gap-2">
                <span>watch video</span>
                <div className="orange_circle_play_video_btn">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="25" cy="25" r="25" fill="#FFE4D8" />
                    <circle cx="24.9999" cy="25" r="18.9655" fill="#EC6E32" />
                    <path
                      d="M30 23.268C31.3333 24.0378 31.3333 25.9623 30 26.7321L24 30.1962C22.6667 30.966 21 30.0037 21 28.4641L21 21.5359C21 19.9963 22.6667 19.034 24 19.8038L30 23.268Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 position-relative">
            <div className="square">
              <img className="sequare" src={sequare} alt="" />
            </div>
            <figure className="mb-0 hero_bg">
              <div className="bg_hero">
                <img src={herobg} alt="" />
              </div>
            </figure>
          </div>
          <img src={rhombus} className="rhombus" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
