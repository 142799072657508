import company1 from "../assets/images/companies/1.png";
import company2 from "../assets/images/companies/2.png";
import company3 from "../assets/images/companies/3.png";
import company4 from "../assets/images/companies/4.png";

const companies = [
  {
    src: company1,
  },
  {
    src: company2
  },
  {
    src: company3
  },
  {
    src: company4,
  },
];

export default companies;
