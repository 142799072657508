import React from 'react'
import aboutbg from "../assets/images/about/bg.png";
import sequare from "../assets/images/shapes/square.svg";
import rhombus from "../assets/images/shapes/rhombus.svg";
import circle from "../assets/images/shapes/circle.svg";
import polygon from "../assets/images/shapes/polygon.svg";
import { useTranslation } from 'react-i18next';

const About = () => {
    const { i18n, t } = useTranslation();

    return (
        <section id="about" className='about default-padding'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 order2" data-aos="fade-right">
                        <article>
                            {/* <h3 className='text-capitalize text-anime-style-3'>{t(`about.title.normal`)} <span>{t(`about.title.span`)}</span></h3> */}
                            <h2 className='section_title text-anime-style-2'>{t(`about.sub`)}</h2>
                            <p>{t(`about.p.p1`)}</p>
                            <p>{t(`about.p.p2`)}</p>
                            <p>{t(`about.p.p3`)}</p>
                        </article>
                    </div>
                    <div className="col-lg-6 col-md-12" data-aos="fade-left">
                        <figure className='mb-5'>
                            <img src={aboutbg} alt="" />
                        </figure>
                    </div>
                </div>

                <img src={sequare} className='sequare' alt="" />
                <img src={rhombus} className='rhombus' alt="" />
                <img src={circle} className='circle' alt="" />
                <img src={polygon} className='polygon' alt="" />
            </div>
        </section>
    )
}

export default About