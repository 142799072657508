import React from 'react'

const PageNotFound = () => {
    return (
        <div className='pagenotfound'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className='fw-bold display-3 text-white'>
                            OOPS!
                        </h2>
                        <p className='text-white fw-bolder fs-25'>
                            The page you are looking for doesn't exist.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <h1 className='text-white fw-bold'>
                            404
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound